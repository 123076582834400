import React, { useState, useEffect } from "react";
import Menu from './Menu.js';
import { useNavigate } from "react-router-dom";
import { Select } from "antd";
import config from "../appsetting.json"; 
import UserModal from './UserEdit';
//photo
import logo from "./images/logo.svg";
import user_icon from "./images/user_icon.png";
import TablePagination from "@mui/material/TablePagination";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/core.css";
import "./styles/style.css";
import "./styles/icon-font.min.css";

const { Option } = Select;

export default function User(props) {
  const Swal = require("sweetalert2"); 
  const navigate = useNavigate();
  const UNAME = localStorage.getItem("UserName");

  const [seletedUserId, setSelectedUserId] = useState('');
  const [AddUserinto, setAdduser] = useState([]);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = useState("ASC");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const tokenAu = localStorage.getItem("token");

  //Level
  const [levels, setLevels] = useState([]);
  const [customers, setCustomers] = useState([]);

  //---------- Modal ----------------
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [action, setAction] = useState('create');

  const openUserEditModal = (action, uid) => {
    setSelectedUserId(uid);
    setAction(action);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  //---------- Modal ----------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const clicklogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const confirmDelete = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been deleted successfully.",
    }).then(() => {
      loadUser();
    });
  };
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...AddUserinto].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setAdduser(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...AddUserinto].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setAdduser(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    loadUser();
    loadLevel();
    loadCustomer();
  }, []);

  const loadUser = () => {
    fetch(config.ApiPathUrl_DevServer + "/Users",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer ' + tokenAu
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        setAdduser(result);
      });
  };


  const loadLevel = () => {
    fetch(config.ApiPathUrl_DevServer + "/Levels",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer ' + tokenAu
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        setLevels(result);
      });
  };


  const loadCustomer = () => {
    fetch(config.ApiPathUrl_DevServer + "/Customer",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer ' + tokenAu
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setCustomers(result);
      });
  };

  const deleteUser = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${tokenAu}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "id": id
    });

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.ApiPathUrl_DevServer + "/Users?id=" + id, requestOptions)
      .then(response => response.text())
      .then(result => {
        confirmDelete();
      })
      .catch(error => console.log('error', error));
  };

  return (
    <div className="scrollbar-main sidebar-light body">
      <div className="header">
        <div className="header-left">
          <div className="menu-icon dw dw-menu pointer"></div>
        </div>
        <div className="header-right">
          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <span className="user-icon">
                  <img src={user_icon} alt="" />
                </span>
                <span className="user-name">{UNAME}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                <a className="dropdown-item" onClick={clicklogOut}>
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-side-bar">
        <div className="brand-logo">
          <a href="/home">
            <img src={logo} alt="" className="dark-logo" width="180px" />
          </a>
          <div className="close-sidebar" data-bs-toggle="left-sidebar-close">
            <i className="ion-close-round"></i>
          </div>
        </div>
        <Menu />
      </div>
      <div className="mobile-menu-overlay"></div>

      <div className="main-container">
        <div className="pd-ltr-20">
          <div className="card-box mb-30">
            <div className="card-body">
              <div className="clearfix">
                <div className="pull-left">
                  <h2 className="h2">User Management</h2>
                </div>
                <div className="pull-right">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm" onClick={() => openUserEditModal('create')}><i className="micon dw dw-add-user mr-2"></i>ADD USER</button>
                  {/* Other buttons to open the modal for update and delete */}
                  <UserModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    action={action}
                    userId={seletedUserId}
                    tokenAu={tokenAu}
                    onRefresh={loadUser}
                  />
                </div>
              </div>
              <div className="table-responsive pd-20">
                <table className="data-table table hover w-100 nowrap">
                  <thead>
                    <tr>
                      <th
                        className="text-center"
                        onClick={() => sorting("userId")}
                      >
                        User ID
                      </th>
                      <th
                        className="text-center"
                        onClick={() => sorting("firstName")}
                      >
                        First Name
                      </th>
                      <th
                        className="text-center"
                        onClick={() => sorting("lastname")}
                      >
                        Last Name
                      </th>
                      <th
                        className="text-center"
                        onClick={() => sorting("userLevel")}
                      >
                        Level
                      </th>
                      <th
                        className="text-center"
                        onClick={() => sorting("customer_id")}
                      >
                        Customer
                      </th>
                      <th className="datatable-nosort text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody >
                    {AddUserinto.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    ).map((item, i) => (
                      <tr key={i}>
                        <td className="text-center">{item.userId}</td>
                        <td className="text-center">{item.firstName}</td>
                        <td className="text-center">{item.lastName}</td>
                        <td className="text-center">
                          {levels.find((l) => l.code == item.userLevel)?.namE_LEVEL} 
                        </td>
                        <td className="text-center">
                          {customers.find((c) => c.id == item.customer_id)?.name}
                        </td>
                        <td className="text-center">
                          <div className="dropdown">
                            <a
                              className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                              href="/#"
                              role="button"
                              data-bs-toggle="dropdown"
                            >
                              <i className="dw dw-more"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                              <a
                                className="dropdown-item"
                                onClick={() => (
                                  openUserEditModal('update', item.userId)
                                )}
                              >
                                <i className="dw dw-edit2"></i> Edit
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={() => deleteUser(item.id)}
                              >
                                <i className="dw dw-trash1"></i> Delete
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
                      count={AddUserinto.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </tbody>
                </table>
              </div>
            </div> 
          </div>
          <div className="footer-wrap pd-20 mb-20 card-box">
            &copy; <span id="copy-year"></span> Copyright All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
