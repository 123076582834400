import React, { useState, useEffect } from "react";
import Menu from './Menu.js';
import { useNavigate } from "react-router-dom";
import config from "../appsetting.json";
// รูป
import logo from "./images/logo.svg";
import user_icon from "./images/user_icon.png";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/core.css";
import "./styles/style.css";
import "./styles/icon-font.min.css";
//หน้าจอ

export default function PurchaseOrder() {

  const UNAME = localStorage.getItem("UserName");
  const [addpono, setAddPoNo] = useState([]);
  const [po, setPO] = useState([]);
  const [datepo, setDatePO] = useState([]);
  const [sales, setSales] = useState([]);
  const [totalprice, setTotalPrice] = useState([]);
  const [order, setOrder] = useState("ASC");
  const navigate = useNavigate(); 
  const [visiblePurchaseOrder, setVisiblePurchaseOrder] = useState(false);
  const [visibleuseraccount, setVisibleUserAccount] = useState(false); 
  
  const UCusKey = localStorage.getItem("User_Key");
  const tokenAu = localStorage.getItem("token");
  const pono = localStorage.getItem("PO_No");

  const [poDetailall, setPoDetailall] = useState([]);
  const [slaeName, setSaleName] = useState([]);

  const [date, setDate] = useState("");

  const clicklogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const getItemPO = () => {
    fetch(config.ApiPathUrl_DevServer +"/Po/Po_no/" + `${pono}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'bearer '+tokenAu
      },
    }
    )
      .then((res) => res.json())
      .then((result) => {
        getSale(result.create_by);
        setPoDetailall(result);
        setDate(result.po_dttm);
        console.log(result);
      });
  };

  const getSale = (Userid) => {
    fetch(config.ApiPathUrl_DevServer + "/Users/{userid}?id=" + `${Userid}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'bearer '+tokenAu
      },
    }
    )
      .then((res) => res.json())
      .then((result) => {
        setSaleName(result.firstName);
      });
  };

  const getMainPO = () => {
    fetch(config.ApiPathUrl_DevServer +"/checkMainPOInPONo/" + `${pono}`)
      .then((res) => res.json())
      .then((result) => {
        setPO(result[0].PO_No);
        setDatePO(result[0].DateInPo);
        setSales(result[0].Sales);
      });
  };
  const getTotalPrice = () => {
    fetch(config.ApiPathUrl_DevServer +"/checkTotalsPriceInPO/" + `${pono}`)
      .then((res) => res.json())
      .then((result) => {
        setTotalPrice(result[0].AllSum);
      });
  };
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...addpono].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setAddPoNo(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...addpono].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setAddPoNo(sorted);
      setOrder("ASC");
    }
  };
  useEffect(() => {
    getItemPO();
    //getMainPO();
    //getTotalPrice(); 
  }, []);
  return (
    <div className="scrollbar-main sidebar-light body">
      <div className="header">
        <div className="header-left">
          <div className="menu-icon dw dw-menu pointer"></div>
        </div>
        <div className="header-right">
          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <span className="user-icon">
                  <img src={user_icon} alt="" />
                </span>
                <span className="user-name">{UNAME}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                <a className="dropdown-item" onClick={clicklogOut}>
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-side-bar">
        <div className="brand-logo">
          <a href="/home">
            <img src={logo} alt="" className="dark-logo" width="180px" />
          </a>
          <div className="close-sidebar" data-bs-toggle="left-sidebar-close">
            <i className="ion-close-round"></i>
          </div>
        </div>
        <Menu/>
      </div>
      <div className="mobile-menu-overlay"></div>

      <div className="main-container">
        <div className="pd-ltr-20">
          <div className="card-box mb-30">
            <div className="card-body">
              <div className="clearfix">
                <div className="pull-left">
                  <h2 className="h2">Purcahse Order</h2>
                </div>
                <div className="pull-right">
                  <a className="btn btn-sm btn-outline-dark" href="/pohistory">
                    <i className="dw dw-left-chevron mr-2"></i>BACK
                  </a>
                </div>
              </div>

              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div className="form-group mb-2">
                      <label>PO No.</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={pono}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-2">
                      <label>Date</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={new Date(date).toLocaleDateString('en-GB')}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-2">
                      <label>Sales Name</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={slaeName}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="table-responsive scrollbar-table">
                  <table className="data-table table hover w-100 nowrap">
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">Product ID</th>
                        <th className="text-center">Qty</th>
                        <th className="text-center">Price</th>
                        <th className="text-center">Total</th>
                        <th className="text-center">Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                    {poDetailall.map((item, i) => (
                      <React.Fragment key={i}>
                        {item.po_Detail.map((detailItem, j) => (
                            <tr key={j}>
                              <td className="text-center">{item.po_no}</td>
                              <td className="text-center">{detailItem.product_id}</td>
                              <td className="text-center">{detailItem.qty}</td>
                              <td className="text-center">{detailItem.price}</td>
                              {/* <td className="text-center">{detailItem.qty * detailItem.price}</td> */}
                              <td className="text-center">{detailItem.remark}</td>
                            </tr>
                            ))}
                          </React.Fragment>
                        ))}
                      </tbody>
                  </table>
                </div>
                <div className="row mb-2 mt-3">
                  <div className="col-md-6 offset-md-6">
                    <div className="form-group mb-2 d-flex justify-content-end align-items-baseline">
                      <label className="mr-2">Total</label>
                      <input
                        type="text"
                        className="form-control form-control-sm mr-2"
                        //value={totalprice}
                        style={{ width: "170px" }}
                        readOnly
                      />
                      <label>Bath</label>
                    </div>
                  </div>
                </div>
                <div className="row mb-2 mt-2">
                  <div className="col-md-6 offset-md-6">
                    <div className="form-group mb-2 d-flex justify-content-end align-items-baseline">
                      <label className="mr-2">Vat 7%</label>
                      <input
                        type="text"
                        className="form-control form-control-sm mr-2"
                        //value={(totalprice * 7) / 100}
                        readOnly
                        style={{ width: "170px" }}
                      />
                      <label>Bath</label>
                    </div>
                  </div>
                </div>
                <div className="row mb-2 mt-2">
                  <div className="col-md-6 offset-md-6">
                    <div className="form-group mb-2 d-flex justify-content-end align-items-baseline">
                      <label className="mr-2">Total Amount</label>
                      <input
                        type="text"
                        className="form-control form-control-sm mr-2"
                        //value={(totalprice * 7) / 100 + totalprice}
                        style={{ width: "170px" }}
                        readOnly
                      />
                      <label>Bath</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-wrap pd-20 mb-20 card-box">
            &copy; <span id="copy-year"></span> Copyright All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
