import React, { useState, useEffect, useRef } from "react";
import Menu from './Menu.js'; 
import { useNavigate } from "react-router-dom";
import config from "../appsetting.json";
import Modal from "react-bootstrap/Modal";
import { Select } from "antd";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
//photo
import logo from "./images/logo.svg";
import user_icon from "./images/user_icon.png";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/core.css";
import "./styles/style.css";
import "./styles/icon-font.min.css";

const { Option } = Select;
export default function AddPO() {
  const [historyData, setHistoryData] = useState([]);
  const [itemNames, setItemNames] = useState([]);
  const [cuscodes, setCusCodes] = useState([]);
  const [itemoption, setItemOption] = useState([]);
  const [cusoption, setCusOption] = useState([]);
  const [amount, setAmount] = useState([]);
  const [unit, setUnit] = useState([]);
  const [priceperunit, setPricePerUnit] = useState([]);
  const [total, setTotal] = useState([]);
  const [remark, setRemark] = useState([]);
  const [pono, setPoNo] = useState([]);
  const [addpono, setAddPoNo] = useState([]);
  const [projectcode, setProjectCode] = useState([]);
  const [date, setDate] = useState(new Date(), "dd/mm/yyyy");
  const [plandeliverydate, setPlanDeliveryDate] = useState([]);
  const [showaddpo, setShowAddPo] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const handleClose = () => setShowAddPo(false);
  const navigate = useNavigate();
  const UNAME = localStorage.getItem("User_Id");
  // const CusCodepo = localStorage.getItem("Cus_Code");
  const [loading, setLoading] = useState(false);
  const [visiblePurchaseOrder, setVisiblePurchaseOrder] = useState(false);
  const [visibleuseraccount, setVisibleUserAccount] = useState(false);

  const UCusKey = localStorage.getItem("User_Key");
  const tokenAu = localStorage.getItem("token");


  //detail
  const [productId,setProductId] = useState("");
  const [unitDetail, setUnitDetail] = useState("");
  const [amountDetail,setAmountDetail] = useState("");
  const [priceperunitDetail,setPricePerUnitDetail] = useState("");
  const [totalDetail,setTotalDetail] = useState("");
  const [remarkDetail,setRemarkDetail] = useState("");
  const [addDetailPO, setAddDetailPO] = useState([]);
  const [idDetail, setIdDetail] = useState(0);



  const addDetail = () => {
    let id = idDetail+1;
    setIdDetail(id);
    const newDetail = { 
      id: id,
      po_key: id,
      product_id: productId,
      qty: amountDetail, 
      price: priceperunitDetail,
      remark: remarkDetail
    };
    setAddDetailPO([...addDetailPO, newDetail])
    //console.log(addDetailPO);
  };

  const deleteDetail = (id) => {
    const newDetails = addDetailPO.filter(detail => detail.id !== id);
    setAddDetailPO(newDetails);
  };



  const clicklogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const onclickConfirmadd = () => {
    AddPOMain();
    confirmAdd();
    //navigate("/purchaseorder");
    navigate("/pohistory");
    localStorage.setItem("PO_No", pono);
  };


  const changeItem = (e) => {
    console.log(e);
    let d = document.getElementsByClassName("ant-select-selector");
    if (e !== "" && e !== undefined) {
      if (d[0].classList.contains("validation-select-invalid")) {
        d[0].classList.remove("validation-select-invalid");
        d[0].className += " validation-select-valid";
      }
      setItemNames(e);
    } else {
      if (d[0].classList.contains("validation-select-valid")) {
        d[0].classList.remove("validation-select-valid");
        d[0].className += " validation-select-invalid";
      }
      setItemNames("");
    }
  };


  const changeCus = (e) => {
    console.log(e);
    let d = document.getElementsByClassName("ant-select-selector");
    if (e !== "" && e !== undefined) {
      if (d[0].classList.contains("validation-select-invalid")) {
        d[0].classList.remove("validation-select-invalid");
        d[0].className += " validation-select-valid";
      }
      setCusCodes(e);
    } else {
      if (d[0].classList.contains("validation-select-valid")) {
        d[0].classList.remove("validation-select-valid");
        d[0].className += " validation-select-invalid";
      }
      setCusCodes("");
    }
  };

  const getAllStockList = async () => {
      await fetch(config.ApiPathUrl_DevServer + "/Product", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
      })
      .then(response => response.json())
      .then(data => {
        const options = data.map(item => <Option key={item.id}>{item.productName}</Option>);
        setItemOption(options);
      })
      .catch(error => {
        console.error(error);
        alert("Error loading product.");
      });
  };

  const loadgetAllCustomer = async () => {
    try {

      fetch(
        config.ApiPathUrl_DevServer + "/Customer",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'bearer '+tokenAu
          },
        }
        )
        .then(response => response.json())
        .then(data => {
          const options = data.map(item => <Option key={item.id}>{item.name}</Option>);
          setCusOption(options);
        })
        .catch(error => {
          console.error(error);
          alert("Error loading customers.");
        });

    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const AddPOMain = () => {
    try {
      fetch(config.ApiPathUrl_DevServer + "/Po",{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
        body: JSON.stringify({
          po_no: pono,
          customer_id: cuscodes,
          po_dttm: date,
          create_by: UCusKey,
          status: "open",
          qty: 0,
          balance: 0,
          po_Detail: addDetailPO,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          if (result.Status == "Success") {
            handleClose(false);
          } else {
            alert(result.Message);
          }
        });
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const confirmAdd = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been added successfully.",
    }).then(() => {
      window.location.reload();
    });
  };
  
  const handleFilter = (items) => {
    return (searchValue) => {
      if (searchValue.length === 0) {
        return itemoption;
      }
      const updatedItems = items.map((list) => {
        const newItems = list.items.filter((item) => {
          return item.name.toLowerCase().includes(searchValue.toLowerCase());
        });
        return { ...list, items: newItems };
      });
      return updatedItems;
    };
  };

  useEffect(() => {
    //getAllHistory();
    getAllStockList();
    loadgetAllCustomer();
    //getItemPO(); 
  }, []);
  return (
    <div className="scrollbar-main sidebar-light body">
      <div className="header">
        <div className="header-left">
          <div className="menu-icon dw dw-menu pointer"></div>
        </div>
        <div className="header-right">
          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <span className="user-icon">
                  <img src={user_icon} alt="" width={"100%"}/>
                </span>
                <span className="user-name">{UNAME}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                <a className="dropdown-item" onClick={clicklogOut}>
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-side-bar">
        <div className="brand-logo">
          <a href="/home">
            <img src={logo} alt="" className="dark-logo" width="180px" />
          </a>
          <div className="close-sidebar" data-bs-toggle="left-sidebar-close">
            <i className="ion-close-round"></i>
          </div>
        </div>
        <Menu/>
      </div>
      <div className="mobile-menu-overlay"></div>

      <div className="main-container">
        <div className="pd-ltr-20">
          <div className="card-box mb-30">
            <div className="card-body">
              <div className="clearfix">
                <div className="pull-left">
                  <h2 className="h2">Add Purcahse Order</h2>
                </div>
                <div className="pull-right">
                  <a
                    className="btn btn-sm btn-outline-dark mr-2"
                    href="/pohistory"
                  >
                    <i className="dw dw-left-chevron mr-2"></i>BACK
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group mb-2">
                    <label>CustomerName</label>
                    <Select
                      style={{ width: "100%" }}
                      // className="custom-select custom-select-sm"
                      showSearch
                      allowClear
                      required
                      placeholder="Select a Item"
                      optionFilterProp="children"
                      onChange={changeCus}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {cusoption}
                    </Select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-2">
                    <label>PO No.</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      required
                      onChange={(e) => setPoNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-2">
                    <label>Date</label>
                    <DatePicker
                      className="form-control form-control-sm"
                      dateFormat="dd/MM/yyyy"
                      selected={date}
                      onChange={(date) => setDate(date)}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="pull-left">
                    <h2 className="h3">Item</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <label>ItemName</label>
                      <Select
                        style={{ width: "100%" }}
                        // className="custom-select custom-select-sm"
                        showSearch
                        allowClear
                        required
                        placeholder="Select a Item"
                        optionFilterProp="children"
                        onChange={setProductId}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {itemoption}
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mb-2">
                      <label>Qty</label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        required
                        onChange={(e) => setAmountDetail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="form-group mb-2">
                      <label>Price</label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        required
                        onChange={(e) => setPricePerUnitDetail(e.target.value)}
                      />
                    </div>
                  </div>
                  
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mb-2">
                      <label>Remark</label>
                      <textarea
                        className="form-control form-control-sm"
                        type="text"
                        onChange={(e) => setRemarkDetail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5"></div>
                  <div className="col-md-3 d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-md btn-primary mt-md-4 mt-2"
                      // onClick={() => (AddItem, AddItemfordelivery)}
                      onClick={() => addDetail()}
                    >
                      <i className="dw dw-add mr-2"></i>ADD
                    </button>
                  </div>
                  <div className="col-md-4"></div>
                </div>
              </div>
              <div className="table-responsive scrollbar-table">
                <table className="data-table table hover w-100 nowrap">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center">ItemName</th>
                      <th className="text-center">Qty</th>
                      <th className="text-center">Price</th>
                      <th className="text-center">Remark</th>
                      <th className="datatable-nosort text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addDetailPO.map((item, i) => (
                      <tr key={i}>
                        <td className="text-center">{item.id}</td>
                        <td className="text-center">{item.product_id}</td>
                        <td className="text-center">{item.qty}</td>
                        <td className="text-center">{item.price}</td>
                        <th className="text-center">{item.remark}</th>
                        <td className="text-center">
                          <a
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => deleteDetail(item.id)}
                          >
                            <i className="dw dw-trash1"></i> Delete
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className=" text-right">
                <button
                  type="button"
                  className="btn btn-primary btn-md"
                  onClick={() => setShowQr(true)}
                >
                  <i className="micon dw dw-diskette1 mr-2"></i>SAVE
                </button>
              </div>
              {/* <!-- ยืนยัน Modal --> */}
              <Modal
                show={showQr}
                onHide={() => setShowQr(false)}
                dialogClassName="modal-50w"
                aria-labelledby="cf"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="cf">ยืนยันข้อมูล</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <h5>ต้องการยืนยันข้อมูลใช่หรือไม่</h5>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="col-lg-12 text-center">
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-sm mx-2"
                      data-dismiss="modal"
                      onClick={() => setShowQr(false)}
                    >
                      <i className="micon dw dw-cancel mr-2"></i>CANCEL
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm mx-2"
                      onClick={onclickConfirmadd}
                    >
                      <i className="micon dw dw-diskette1 mr-2"></i>SAVE
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <div className="footer-wrap pd-20 mb-20 card-box">
            &copy; <span id="copy-year"></span> Copyright All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
