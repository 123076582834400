import React, { useState,useEffect } from "react";
import Menu from './Menu.js'; 
import { useNavigate } from "react-router-dom";
import config from "../appsetting.json";
//photo
import loginpageimg from "./images/loginpageimg.png";
import TGL_logo from "./images/TGL_logo.png";
import logo from "./images/logo.svg";
import user_icon from "./images/user_icon.png";
// css
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/core.css";
import "./styles/style.css";
import "./styles/icon-font.min.css";

export default function ConfirmOrder() {
  const UNAME = localStorage.getItem("UserName");
  const levelcode = localStorage.getItem("Level_Code");
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const clicklogOut = () => {
    localStorage.clear();
    navigate("/");
  };  

  useEffect(() => { 
  }, []);  return (
    <div className="scrollbar-main sidebar-light body">
      <div className="header">
        <div className="header-left">
          <div className="menu-icon dw dw-menu pointer"></div>
        </div>
        <div className="header-right">
          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <span className="user-icon">
                  <img src={user_icon} alt="" />
                </span>
                <span className="user-name">{UNAME}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                <a className="dropdown-item" onClick={clicklogOut}>
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-side-bar">
        <div className="brand-logo">
          <a href="/home">
            <img src={logo} alt="" className="dark-logo" width="180px" />
          </a>
          <div className="close-sidebar" data-bs-toggle="left-sidebar-close">
            <i className="ion-close-round"></i>
          </div>
        </div>
        <div className="menu-block customscroll ">
          <div className="sidebar-menu icon-list-style-2 ">
            <ul id="accordion-menu" className="fa-ul pl-0 m-0">
              <li>
                <a href="/home" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-house1"></span>
                  <span className="mtext">Home</span>
                </a>
              </li>
              <li>
                <a href="/hospitallist" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-hospital"></span>
                  <span className="mtext">Hospital List</span>
                </a>
              </li>
              <li>
                <a href="/stockList" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-inbox1"></span>
                  <span className="mtext">Stock List</span>
                </a>
              </li>
              {visible && 
              <li>
                <a href="/pohistory" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-inbox1"></span>
                  <span className="mtext">PurchaseOrder List</span>
                </a>
              </li>}
              {visible && 
              <li className="dropdown">
                <a
                  // href="javascript:;"
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <span className="micon dw dw-user1"></span>
                  <span className="mtext">User Account</span>
                </a>
                <ul className="submenu dropdown-menu">
                  <li>
                    <a href="/customer" className="dropdown-item">
                      Customer
                    </a>
                  </li>
                  <li>
                    <a href="/user" className="dropdown-item">
                      User
                    </a>
                  </li>
                  <li>
                    <a href="/level" className="dropdown-item">
                      Level
                    </a>
                  </li>
                </ul>
              </li>}
            </ul>
          </div>
        </div>
      </div>
      <div className="mobile-menu-overlay"></div>

      <div className="main-container">
        <div className="pd-ltr-20">
          <div className="card-body">
            <div className="card-box mb-30">
              <div className="invoice-wrap">
                <div className="invoice-box w-100">
                  <h4 className="weight-600 text-center mb-30">
                    DELIVERY NOTE
                  </h4>
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <div className="form-group mb-2">
                        <label>PO No.</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value="KKU-22-001"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group mb-2">
                        <label>Ref.</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value="TGL-00-0021"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group mb-2">
                        <label>Date</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value="20/09/2022"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-2">
                        <label>Customer Name</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value="คณะแพทย์ศาสตร์ มหาวิทยาลัยขอนแก่น โรงพยาบาลศรีนครินทร์"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-2">
                        <label>Address</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value="123 ถ.มิตรภาพ อำเภอเมืองขอนแก่น ขอนแก่น 40002"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="invoice-desc pb-30">
                    <div className="table-responsive scrollbar-table">
                      <table className="data-table table hover w-100 nowrap">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Part Name / Color No. / Size</th>
                            <th>Part No. / Product Code</th>
                            <th>Qty</th>
                            <th>Remark</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              Y-Connection Y adapter tubing w/Dual check valve
                            </td>
                            <td>1MKTGB0054</td>
                            <td>300.00</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <form>
                    <div className="form-group mb-3">
                      <label>Remark</label>
                      <textarea className="form-control" required></textarea>
                    </div>
                    <div className="d-flex justify-content-center flex-wrap">
                      <button
                        type="submit"
                        className="btn btn-outline-danger btn-sm mr-3"
                        // onClick="cancelRecieve()"
                      >
                        <i className="micon dw dw-cancel mr-2"></i>CANCEL
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary btn-sm"
                        // onClick="confirmRecieve()"
                      >
                        <i className="micon dw dw-checked mr-2"></i>CONFIRM
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="footer-wrap pd-20 mb-20 card-box">
              &copy; <span id="copy-year"></span> Copyright All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
