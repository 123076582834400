import React, { useState, useEffect } from "react";
import Menu from './Menu.js';
import { useNavigate } from "react-router-dom";
import config from "../appsetting.json";
// รูป
import logo from "./images/logo.svg";
import user_icon from "./images/user_icon.png";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/core.css";
import "./styles/style.css";
import "./styles/icon-font.min.css";
//หน้าจอ

export default function HospitalList() {
  const [hospitalinfo, setHospitalInfo] = useState([]);
  const [cuslogin, setCusLogin] = useState([]);
  const navigate = useNavigate();
  const [order, setOrder] = useState("ASC");
  const UNAME = localStorage.getItem("UserName");
  const [visibletablehospitallist, setVisibleTableHospitalList] =
    useState(false);
  const [visiblePurchaseOrder, setVisiblePurchaseOrder] = useState(false);
  const [visibleuseraccount, setVisibleUserAccount] = useState(false);
  const levelcode = localStorage.getItem("Level_Code");
  const CusLoginCusCode = localStorage.getItem("Cus_Code");

  const loadAllCustomer = () => {
    fetch(config.ApiPathUrl_DevServer + "/getAllCustomer/")
      .then((res) => res.json())
      .then((result) => {
        setHospitalInfo(result.Data);
      });
  };
  // const sorting = (col) => {
  //   if (order === "ASC") {
  //     const sorted = [...hospitalinfo].sort((a, b) =>
  //       a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
  //     );
  //     setHospitalInfo(sorted);
  //     setOrder("DSC");
  //   }
  //   if (order === "DSC") {
  //     const sorted = [...hospitalinfo].sort((a, b) =>
  //       a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
  //     );
  //     setHospitalInfo(sorted);
  //     setOrder("ASC");
  //   }
  // };
  const loadCusLog = async () => {
    try {
      await fetch(
        config.ApiPathUrl_DevServer + "/getCustomer/" + `${CusLoginCusCode}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          setCusLogin(result);
        });
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const clicklogOut = () => {
    localStorage.clear();
    navigate("/");
  };
  useEffect(() => {
    loadAllCustomer(); 
    loadCusLog();
  }, []);
  return (
    <div className="scrollbar-main sidebar-light body">
      <div className="header">
        <div className="header-left">
          <div className="menu-icon dw dw-menu pointer"></div>
        </div>
        <div className="header-right">
          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <span className="user-icon">
                  <img src={user_icon} alt="" />
                </span>
                <span className="user-name">{UNAME}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                <a className="dropdown-item" onClick={clicklogOut}>
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-side-bar">
        <div className="brand-logo">
          <a href="/home">
            <img src={logo} alt="" className="dark-logo" width="180px" />
          </a>
          <div className="close-sidebar" data-bs-toggle="left-sidebar-close">
            <i className="ion-close-round"></i>
          </div>
        </div>
        <div className="menu-block customscroll">
          <div className="sidebar-menu icon-list-style-2">
            <ul id="accordion-menu" className="fa-ul pl-0 m-0">
              <li>
                <a href="/home" className="dropdown-toggle no-arrow ">
                  <span className="micon dw dw-house1"></span>
                  <span className="mtext">Home</span>
                </a>
              </li>
              <li>
                <a href="/hospitallist" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-hospital"></span>
                  <span className="mtext">Hospital List</span>
                </a>
              </li>
              <li>
                <a href="/stockList" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-inbox1"></span>
                  <span className="mtext">Stock List</span>
                </a>
              </li>
              {visiblePurchaseOrder && (
                <li>
                  <a href="/pohistory" className="dropdown-toggle no-arrow">
                    <span className="micon dw dw-inbox1"></span>
                    <span className="mtext">PurchaseOrder List</span>
                  </a>
                </li>
              )}
              {visibleuseraccount && (
                <li className="dropdown">
                  <a
                    // href="javascript:;"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <span className="micon dw dw-user1"></span>
                    <span className="mtext">User Account</span>
                  </a>
                  <ul className="submenu dropdown-menu fixdropdown">
                    <li>
                      <a href="/customer" className="dropdown-item">
                        Customer
                      </a>
                    </li>
                    <li>
                      <a href="/user" className="dropdown-item">
                        User
                      </a>
                    </li>
                    <li>
                      <a href="/level" className="dropdown-item">
                        Level
                      </a>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="mobile-menu-overlay"></div>

      <div className="main-container">
        <div className="pd-ltr-20">
          {visibletablehospitallist && (
            <div className="card-box mb-30">
              <div className="card-body">
                <div className=" align-items-center m-8">
                  <h2 className="h2 ">Hospital List</h2>
                </div>

                <div className="table-responsive scrollbar-table">
                  <table className="data-table table hover w-100 nowrap">
                    <thead>
                      <tr>
                        <th
                          className="text-center"
                          // onClick={() => sorting("Cus_Code")}
                        >
                          #
                        </th>
                        <th
                          className="text-center"
                          // onClick={() => sorting("Cus_Name")}
                        >
                          Customer Name
                        </th>
                        <th
                          className="text-center"
                          // onClick={() => sorting("Cus_Address")}
                        >
                          Address
                        </th>
                        <th
                          className="text-center"
                          // onClick={() => sorting("Cus_Contact")}
                        >
                          Contact Us.
                        </th>
                        <th
                          className="text-center"
                          // onClick={() => sorting("Sales")}
                        >
                          Sales
                        </th>
                        <th className="datatable-nosort text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {hospitalinfo.map((item, i) => (
                        <tr key={i}>
                          <td className="text-center">{item.Cus_Code}</td>
                          <td>{item.Cus_Name}</td>
                          <td>{item.Cus_Address}</td>
                          <td className="text-center">0{item.Cus_Contact}</td>
                          <td>{item.Sales}</td>
                          <td className="text-center">
                            <a
                              className="btn btn-sm btn-outline-primary"
                              href="/hospitaldelivery"
                              onClick={() =>
                                localStorage.setItem("Cus_Code", item.Cus_Code)
                              }
                            >
                              <i className="dw dw-eye mr-2"></i>View
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {!visibletablehospitallist && (
            <div className="card-box mb-30">
              <div className="card-body">
                <div className=" align-items-center m-8">
                  <h2 className="h2 ">Hospital List</h2>
                </div>

                <div className="table-responsive scrollbar-table">
                  <table className="data-table table hover w-100 nowrap">
                    <thead>
                      <tr>
                        <th
                          className="text-center"
                          // onClick={() => sorting("Cus_Code")}
                        >
                          #
                        </th>
                        <th
                          className="text-center"
                          // onClick={() => sorting("Cus_Name")}
                        >
                          Customer Name
                        </th>
                        <th
                          className="text-center"
                          // onClick={() => sorting("Cus_Address")}
                        >
                          Address
                        </th>
                        <th
                          className="text-center"
                          // onClick={() => sorting("Cus_Contact")}
                        >
                          Contact Us.
                        </th>
                        <th
                          className="text-center"
                          // onClick={() => sorting("Sales")}
                        >
                          Sales
                        </th>
                        <th className="datatable-nosort text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cuslogin.map((item, i) => (
                        <tr key={i}>
                          <td className="text-center">{item.Cus_Code}</td>
                          <td>{item.Cus_Name}</td>
                          <td>{item.Cus_Address}</td>
                          <td className="text-center">0{item.Cus_Contact}</td>
                          <td>{item.Sales}</td>
                          <td className="text-center">
                            <a
                              className="btn btn-sm btn-outline-primary"
                              href="/hospitaldelivery"
                              onClick={() =>
                                localStorage.setItem("Cus_Code", item.Cus_Code)
                              }
                            >
                              <i className="dw dw-eye mr-2"></i>View
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <div className="footer-wrap pd-20 mb-20 card-box">
            &copy; <span id="copy-year"></span> Copyright All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
