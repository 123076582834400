import React from 'react';

export default function LoadingScreen() {
    return (
        <div className="loader-wrapper">
            <div className="text">LOADING...</div>
            <div className="loader">
                <div className="ball"></div>
                <div className="ball"></div>
                <div className="ball"></div>
            </div>
        </div>
    )
}
