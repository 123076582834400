import React, { useState, useEffect, useCallback } from 'react';
import config from "../appsetting.json";
import { Modal, Button, Form } from 'react-bootstrap';
import { Select } from 'antd';

const { Option } = Select;

const UserModal = ({ isOpen, onClose, action, userId, tokenAu, onRefresh }) => {
  const Swal = require("sweetalert2");
  const [id, setId] = useState(0);
  const [user_id, setUserId] = useState(userId || '');
  const [user_pwd, setUserPwd] = useState('');
  const [Last_Name, setLastName] = useState('');
  const [first_Name, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [suffix, setSuffix] = useState('');
  const [prefix, setPrefix] = useState('');
  const [degree, setDegree] = useState('');
  const [userLevel, setUserLevel] = useState('');
  const [email, setEmail] = useState('');
  const [user_roles, setUserRoles] = useState('');
  const [customer_id, setCustomerId] = useState('');
  const [position, setPosition] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const validateForm = () => {
    const errors = {};
  
    if (!user_id) {
      errors.user_id = 'User ID is required';
    }
    if (!user_pwd) {
      errors.user_pwd = 'User Password is required';
    }
    if (!Last_Name) {
      errors.Last_Name = 'User Lastname is required';
    }
    if (!first_Name) {
      errors.first_Name = 'User Firstname is required';
    }
    if (!userLevel) {
      errors.userLevel = 'User Level is required';
    } 
    // Add other mandatory field validations here
  
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
  // --- Master Data ----
  const [customers, setCustomers] = useState([]);
  const [levels, setLevels] = useState([]);
 
  // ... Your existing UserModal component code
  const AddOrUpdateUser = () => {
    if (!validateForm()) {
      return;
    }else{try {
      let useMethod = "PUT";
      if(id===0)
      {
        useMethod = "POST"
      } 
      fetch(config.ApiPathUrl_DevServer + "/Users", {
        method: useMethod,
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer ' + tokenAu
        },
        body: JSON.stringify({
          id: id,
          userId: user_id,
          password: user_pwd,
          lastName: Last_Name,
          firstName: first_Name,
          midddleName: "",
          suffix: "",
          prefix: "",
          degree: "",
          userLevel: userLevel,
          email: email,
          user_roles: "",
          customer_id: customer_id,
          position: position
        }),
      })
        .then(response => {
          console.log(response)
          if (response.status === 200) {
            AddSuccess();
          }else {
            const errorData = response.json();
            setErrorMessage(errorData.message || 'An error occurred while saving the user.');
          }
        })
        .then(data => {
          console.log(data);
        })
        .catch(error => console.error(error));
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }
  };

  const AddSuccess = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been added successfully.",
    }).then(() => {
      onRefresh();
      onClose();
    });
  };

  const handleSave = () => {
    setErrorMessage('');
    if (action === 'create') {
      checkUserID(user_id);
      // AddUser();
    } else if (action === 'update') {
      AddOrUpdateUser();
    } else if (action === 'delete') {
      // Call the deleteUser function
    }
  };
  const loadUserLevels = useCallback(async () => {
    fetch(
      config.ApiPathUrl_DevServer + "/Levels",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
      }
      )
      .then(response => response.json())
      .then(data => {
        setLevels(data);
      });
  }, [tokenAu]);

  const loadCustomers = useCallback(async () => {
    fetch(config.ApiPathUrl_DevServer + "/Customer", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + tokenAu,
      },
    })
      .then((response) => response.json())
      .then((data) => { 
        console.log(data);
        setCustomers(data);
      });
  }, [tokenAu]);

  const checkUserID = async (user_id) => {
    let isDup = false; 
      try {
        await fetch(config.ApiPathUrl_DevServer + "/Users/" + user_id, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + tokenAu,
          }, 
        })
          .then((res) => res.json())
          .then((data) => { 
            console.log(data);
            if(data.status == "200"){
                setErrorMessage("มี UserID นี้ในระบบแล้ว");
                isDup = true;
            }
          });
      } catch (error) {
        console.log(error);
      }
      if(!isDup){
        AddOrUpdateUser();
      }  
  }; 

  const fetchUserData = useCallback(async () => {
    if (userId) {
      try {
        const response = await fetch(config.ApiPathUrl_DevServer + "/Users/userid/" + userId, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + tokenAu,
          },
        });

        if (response.ok) {
          const data = await response.json();
          // Pre-fill the form with existing user data
          setId(data.id);
          setUserId(data.userId);
          //setPassword(data.password);
          setLastName(data.lastName);
          setFirstName(data.firstName);
          setMiddleName(data.middleName);
          setSuffix(data.suffix);
          setPrefix(data.prefix);
          setDegree(data.degree);
          setUserLevel(data.userLevel);
          setEmail(data.email);
          setUserRoles(data.user_roles);
          setCustomerId(data.customer_id);
          setPosition(data.position);
        } else {
          console.error("Error fetching user data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    } else {
      console.error("Error fetching user data:", userId);
    }
  }, [tokenAu, userId]);

  useEffect(() => {
    if (isOpen) {
      loadCustomers();
      loadUserLevels();
      if (action === 'update') {
        fetchUserData();
      } else {
        // Reset the form to initial values

        setUserId('');
        setUserPwd('');
        setLastName('');
        setFirstName('');
        setMiddleName('');
        setSuffix('');
        setPrefix('');
        setDegree('');
        setUserLevel('');
        setEmail('');
        setUserRoles('');
        setCustomerId('');
        setErrorMessage('');
        setPosition('');
        setValidationErrors({});
      }
    }
  }, [isOpen, action, userId, loadCustomers,loadUserLevels, fetchUserData]);
  //------------------------
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{action.toUpperCase()} User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        <Form>
          <Form.Group controlId="userId">
            <Form.Label>User ID</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="ID"
              maxLength="16"
              pattern="[a-zA-Z0-9]+"
              value={user_id}
              onChange={(e) => setUserId(e.target.value)}
            />
            {validationErrors.user_id && (
              <div className="text-danger">{validationErrors.user_id}</div>
            )}
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder="Password"
              maxLength="16" 
              value={user_pwd}
              onChange={(e) => setUserPwd(e.target.value)}
            />
            {validationErrors.user_pwd && (
              <div className="text-danger">{validationErrors.user_pwd}</div>
            )}
          </Form.Group>
          <Form.Group controlId="firstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
            required
              type="text"
              value={first_Name}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {validationErrors.first_Name && (
              <div className="text-danger">{validationErrors.first_Name}</div>
            )}
          </Form.Group>
          <Form.Group controlId="lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
            required
              type="text"
              value={Last_Name}
              onChange={(e) => setLastName(e.target.value)}
            />
            {validationErrors.Last_Name && (
              <div className="text-danger">{validationErrors.Last_Name}</div>
            )}
          </Form.Group>

          {/*           <Form.Group controlId="middleName">
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              type="text"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            />
          </Form.Group> 
          <Form.Group controlId="suffix">
            <Form.Label>Suffix</Form.Label>
            <Form.Control
              type="text"
              value={suffix}
              onChange={(e) => setSuffix(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="prefix">
            <Form.Label>Prefix</Form.Label>
            <Form.Control
              type="text"
              value={prefix}
              onChange={(e) => setPrefix(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="degree">
            <Form.Label>Degree</Form.Label>
            <Form.Control
              type="text"
              value={degree}
              onChange={(e) => setDegree(e.target.value)}
            />
          </Form.Group>*/}
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control 
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              />
          </Form.Group>

          <Form.Group controlId="positon">
            <Form.Label>Positon</Form.Label>
            <Form.Control 
              type="text"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="userLevel">
            <Form.Label>User Level *</Form.Label>
            
            <Select
              style={{ width: "100%" }}
              showSearch
              value={userLevel}
              onChange={(value) => setUserLevel(value)}
              placeholder="Select Level"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {levels.map((level) => (
                <Option key={level.code} value={level.code}>
                  {level.namE_LEVEL}
                </Option>
              ))}
            </Select>
            {validationErrors.userLevel && (
              <div className="text-danger">{validationErrors.userLevel}</div>
            )}
          </Form.Group>
          {/* <Form.Group controlId="userRoles">
            <Form.Label>User Roles</Form.Label>
            <Form.Control
              type="text"
              value={user_roles}
              onChange={(e) => setUserRoles(e.target.value)}
            />
          </Form.Group> */}
          <Form.Group controlId="customerId">
            <Form.Label>Customer ID</Form.Label> 
            <Select
              style={{ width: "100%" }}
              showSearch
              value={customer_id}
              onChange={(value) => setCustomerId(value)}
              placeholder="Select Customer"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {customers.map((customer) => (
                <Option key={customer.id} value={customer.id}>
                  {customer.name}
                </Option>
              ))}
            </Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" type="submit"  onClick={handleSave} >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );

};

export default UserModal;
