import React, { useState, useEffect } from "react";
import Menu from './Menu.js'; 

// รูป
import logo from "./images/logo.svg";
import user_icon from "./images/user_icon.png";
import banner_img from "./images/banner_img.png";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/core.css";
import "./styles/style.css";
import "./styles/icon-font.min.css";
//หน้าจอ

export default function Home() {
  const UNAME = localStorage.getItem("UserName"); 
  const clicklogOut = () => {
    localStorage.clear();
  };

  useEffect(() => {
    
  }, []);

  return (
    <div className="scrollbar-main sidebar-light body">
      <div className="header">
        <div className="header-left">
          <div className="menu-icon dw dw-menu pointer"></div>
        </div>
        <div className="header-right">
          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <span className="user-icon">
                  <img src={user_icon} alt="" width={"100%"}/>
                </span>
                <span className="user-name">{UNAME}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                <a className="dropdown-item" onClick={clicklogOut} href="/">
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="left-side-bar">
        <div className="brand-logo">
          <a href="/home">
            <img src={logo} alt="" className="dark-logo" width="180px" />
          </a>
          <div className="close-sidebar" data-bs-toggle="left-sidebar-close">
            <i className="ion-close-round"></i>
          </div>
        </div>
        <Menu/>
      </div>
      <div className="mobile-menu-overlay"></div>

      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20">
          <div className="card-box pd-20 height-100-p mb-30">
            <div className="row align-items-center">
              <div className="col-sm-4">
                <img src={banner_img} alt="" width="180px" />
              </div>
              <div className="col-sm-8">
                <h4 className="font-20 weight-500 mb-10 text-capitalize mt-2 mt-sm-0">
                  Welcome back{" "}
                  <div className="weight-600 font-30 text-green">{UNAME}!</div>
                </h4>
                {/* <p className="font-14 max-width-600 text-secondary">
                  You last access system was 19/08/2022 03:43
                </p> */}
              </div>
            </div>
          </div>
          <div className="footer-wrap pd-20 mb-20 card-box">
            &copy; <span id="copy-year"></span> Copyright All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}