import React, { useState, useEffect } from "react";
import Menu from './Menu.js'; 
import { useNavigate } from "react-router-dom";
import config from "../appsetting.json";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Form, Card } from "react-bootstrap";
//photo
import logo from "./images/logo.svg";
import user_icon from "./images/user_icon.png";
// css
import "./styles/core.css";
import "./styles/icon-font.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/style.css";

export default function Product() {
  const [CusData, setCusData] = useState([]);
  const Swal = require("sweetalert2");
  const handleClose = () => setShow(false);
  const handleEditClose = () => setShowEdit(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [showmodaladd, setShowModalAdd] = useState(false);
  const [showedit, setShowEdit] = useState(false);
  const navigate = useNavigate();
  
  const UNAME = localStorage.getItem("User_Id");
  const UCusKey = localStorage.getItem("User_Key");
  const tokenAu = localStorage.getItem("token");

  const [procodeshow, setProCodeShow] = useState("");

  //get
  const [procode, setProcode] = useState("");
  const [proname, setProname] = useState("");
  const [proprice, setProprice] = useState("");

  //getall
  const [editprocode, setEditProcode] = useState("");
  const [editproname, setEditProname] = useState("");
  const [editproprice, setEditProprice] = useState("");
  const [editproId, setEditProId] = useState("");


  const [productshow, setProductshow] = useState([]);

  const [validated, setValidated] = useState(false);
  const [visible, setVisible] = useState(false);  


  
  const getProduct = () => {
     fetch(
      config.ApiPathUrl_DevServer + "/Product",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
      }
      )
      .then((res) => res.json())
      .then((result) => {
        setProductshow(result);
      });
  };


  const clicklogOut = () => {
    localStorage.clear();
    navigate("/");
  };


  const confirmAdd = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Your data has been added successfully.",
    }).then(() => {
      window.location.reload();
    });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      AddProduct();
    }
    setValidated(true);
  };


  const AddProduct = () => {

    fetch(config.ApiPathUrl_DevServer + "/Product", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${tokenAu}`
      },
      body: JSON.stringify({
        id: 0,
        productCode: procode,
        productName: proname,
        price: 0,
        create_by: UCusKey,
        update_by: 0,
        qty: proprice,
        unit: "",
        description: ""
      })
    })
      .then(response => {
        console.log(response)
        if(response.status == 200){
          confirmAdd();
        }
      })
      .then(data => {
        //console.log(data);
      })
      .catch(error => console.error(error));
  };


  //edit
  const confirmEdit = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been saved successfully.",
    }).then(() => {
      window.location.reload();
    });
  };


  const loadProductByCode = async (id) => {
    try {
      await fetch(
       config.ApiPathUrl_DevServer + "/Product/"+id,
       {
         method: "GET",
         headers: {
           "Content-Type": "application/json",
           'Authorization': 'bearer '+tokenAu
         },
      }).then((res) => res.json())
      .then((result) => {
        console.log(result);
        setEditProId(result.id);
        setEditProcode(result.productCode);
        setEditProname(result.productName);
        setEditProprice(result.qty);
      });
    } catch (error) {
      //console.log(error);
      //alert(error);
    }
  };


  const editProduct = async () => {
      await fetch(config.ApiPathUrl_DevServer + "/Product", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
        body: JSON.stringify({
          id: procodeshow,
          productCode: editprocode,
          productName: editproname,
          price: 0,
          create_by: editproId,
          update_by: UCusKey,
          qty: editproprice,
          description: ""
        }),
      })
      .then(response => {
        //console.log(response)
        if(response.status == 200){
          confirmEdit();
        }
      })
      .then(data => {
        //console.log(data);
      })
      .catch(error => console.error(error));
  };

  const confirmDelete = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been deleted successfully.",
    }).then(() => {
      window.location.reload();
    });
  };

  const deleteProduct = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${tokenAu}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "id": id
    });

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.ApiPathUrl_DevServer + "/Product?id="+id, requestOptions)
      .then(response => response.text())
      .then(result => {
        confirmDelete();
      })
      .catch(error => console.log('error', error));
  };

  var num_x = 1;
  useEffect(()=>{
    if(num_x == 1){
      getProduct();
      num_x++
    }
  },[]);


  return (
    <div className="scrollbar-main sidebar-light body">
      <div className="header">
        <div className="header-left">
          <div className="menu-icon dw dw-menu pointer"></div>
        </div>
        <div className="header-right">
          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <span className="user-icon">
                  <img src={user_icon} alt="" width="100%"/>
                </span>
                <span className="user-name">{UNAME}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                <a className="dropdown-item" onClick={clicklogOut}>
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-side-bar">
        <div className="brand-logo">
          <a href="/home">
            <img src={logo} alt="" className="dark-logo" width="180px" />
          </a>
          <div className="close-sidebar" data-bs-toggle="left-sidebar-close">
            <i className="ion-close-round"></i>
          </div>
        </div>
        <Menu/>
      </div>
      <div className="mobile-menu-overlay"></div>

      <div className="main-container">
        <div className="pd-ltr-20">
          <div className="card-box mb-30">
            <div className="card-body">
              <div className="clearfix">
                <div className="pull-left">
                  <h2 className="h2">Product Management</h2>
                </div>
                <div className="pull-right">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    // data-bs-toggle="modal"
                    // data-target="#add-data"
                    onClick={() => setShow(true)}
                  >
                    <i className="micon dw dw-add-user mr-2"></i>ADD Product
                  </button>
                </div>
              </div>

              <div className="table-responsive scrollbar-table">
                <table className="data-table table hover w-100 nowrap">
                  <thead>
                    <tr>
                      <th className="text-center">Product Code</th>
                      <th className="text-center">Product Name</th>
                      <th className="text-center">Qty</th>
                      <th className="datatable-nosort text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productshow.map((pro, i) => (
                      <tr key={i}>
                        <td className="text-center">{pro.productCode}</td>
                        <td className="text-center">{pro.productName}</td>
                        <td className="text-center">{pro.qty}</td>
                        <td className="text-center">
                          <div className="dropdown">
                            <a
                              className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                              href="/#"
                              role="button"
                              data-bs-toggle="dropdown"
                            >
                              <i className="dw dw-more"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                              <a
                                className="dropdown-item"
                                onClick={() => (
                                  loadProductByCode(pro.id),
                                  setShowEdit(true),
                                  setProCodeShow(pro.id)
                                  
                                )}
                              >
                                <i className="dw dw-edit2"></i> Edit
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={() => deleteProduct(pro.id)}
                              >
                                <i className="dw dw-trash1"></i> Delete
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* <!-- Add Modal --> */}
            <Modal
              show={show}
              onHide={() => setShow(false)}
              dialogClassName="modal-90w"
              aria-labelledby="Customer"
            >
              <Modal.Header closeButton>
                <Modal.Title id="Customer">Add Product</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <main className="content">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row className="justify-content-center">
                        <div className="col-md-6 col-xl-12">
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Product Code</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Product Code"
                                //value={cus_name}
                                onChange={(e) => setProcode(e.target.value)}
                              />
                              {/* <span className="error" style={{color: "red"}}>{errorValidateUserName}</span> */}
                              {/* value={user_name}  onChange={(e) => handleChangeUserName(e)} */}
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Product Name</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Product Name"
                                // value={cus_address}
                                onChange={(e) => setProname(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Qty</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Qty"
                                //value={cus_contact}
                                onChange={(e) => setProprice(e.target.value)}
                              />
                            </Form.Group>
                          </Col>

                        </div>
                      </Row>
                      <div className="mt-3 d-flex justify-content-center"></div>
                    </Form>
                  </main>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  <i className="micon dw dw-cancel mr-2"></i>CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  //onClick="confirmAdd()"
                  onClick={AddProduct}
                >
                  <i className="micon dw dw-add mr-2"></i>ADD
                </button>
              </Modal.Footer>
            </Modal>

            {/* <!-- Edit Modal --> */}
            <Modal
              show={showedit}
              onHide={() => setShowEdit(false)}
              dialogClassName="modal-90w"
              aria-labelledby="EditProduct"
            >
              <Modal.Header closeButton>
                <Modal.Title id="EditProduct">Edit Product</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <main className="content">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row className="justify-content-center">
                        <div className="col-md-6 col-xl-12">
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Product Code</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Product Code"
                                value={editprocode}
                                onChange={(e) => setEditProcode(e.target.value)}
                                //readOnly
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Product Name</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Product Name"
                                value={editproname}
                                onChange={(e) => setEditProname(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Qty</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Qty"
                                value={editproprice}
                                onChange={(e) =>
                                    setEditProprice(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          
                        </div>
                      </Row>
                      <div className="mt-3 d-flex justify-content-center"></div>
                    </Form>
                  </main>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  <i className="micon dw dw-cancel mr-2"></i>CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={editProduct}
                >
                  <i className="micon dw dw-diskette1 mr-2"></i>SAVE
                </button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="footer-wrap pd-20 mb-20 card-box">
            &copy; <span id="copy-year"></span> Copyright All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
